import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import SEO from "../components/SEO";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import SVGCurve from "../components/SVGCurve";
import OrderedList from "../components/OrderedList";

function AboutPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Psychiatry`, `Therapy`, `Psychotherapy`, `Telepsychiatry`, `Counseling`,
                `Appointments`,`Medication Management`]}
                title="Appointments"
                description="Agile Psychiatry offers medication management, counseling, and combined appointments under
                the care of Brian Nahas, MD"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Appointments coming soon! (Updated 1/4/2021)</h2>
                        <TextParagraph className="mb-8">
                            Agile Psychiatry is currently in the process of launching and will be open for appointment
                            scheduling in the near future. This page will be updated with an online form to request
                            an appointment after launch. Please check back soon if you would like to schedule
                            an appointment.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Appointment Request Process</h2>
                        <TextParagraph>
                            Below is the process to <span className="underline">request</span> an appointment at Agile
                            Psychiatry. Note that after the request is submitted, Dr. Nahas will contact you to discuss
                            your request and make sure that Agile Psychiatry is a good fit for your needs.
                            An appointment <span className="underline">must be confirmed</span> with Dr. Nahas before
                            it will take place.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <TextParagraph className="my-4">
                            <OrderedList>
                                <li>Select an Initial Visit in the form below</li>
                                <li>Complete the pre-appointment questionnaire and select a time</li>
                                <li>Dr. Nahas will contact you for a brief (15-minute) phone call to discuss your
                                    request and to make sure Agile Psychiatry can meet your needs</li>
                                <li>If your appointment is confirmed, sign up for the patient portal and complete
                                    the intake forms</li>
                            </OrderedList>
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                <iframe width="100%" height="1000"
                        src="https://ehr.charmtracker.com/publicCal.sas?method=getCal&digest=1d15d1c82ff5a7261f015d95295b6059f46e0f02983651ba049bb9a89a343a119c898e7ad26733530e4b197b50fc1f07debf17855dc5f624"
                        style={{overflow: "hidden;"}} frameBorder="0"></iframe>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <TextDiv className="h-20"/>
                </SectionRow>
            </Section>
        </LayoutGeneral>
    );
}

export default AboutPage;
